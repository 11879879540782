.socialHeader {
  font-size: 1em;
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  padding: 0 2em;
  background: #317cfb;
}

.socialHeader > div {
  font-size: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  padding: 1.4em 0;
  background: #317cfb;
  border-top: 2px solid #fff;
}

.socialHeader a {
  background: none;
  color: #fff;
  border: 0;
  outline: 0;
  border-right: 1px solid #fff;
  font-size: 1em;
  padding: 0 1.5em;
  cursor: pointer;
  text-decoration: none;
}

.socialHeader p {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

@media (max-width: 780px) {
  .socialHeader {
    padding: 0 1em;
  }
  .socialHeader > div {
    padding: 1em 0;
    flex-direction: column;
    text-align: center;
  }

  .socialHeader a:nth-child(2) {
    border: 0;
  }
  .socialHeader p {
    padding-top: 1em;
  }
}
