.contact {
}

.contactButton {
  width: 100%;
  font-size: 1em;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0 0 1em 0;
}

.contactButton > p {
  font-size: calc(25px + (70 - 25) * ((100vw - 350px) / (2500 - 350)));
  text-align: center;
  padding: 0;
  color: #555;
  font-weight: 600;
  margin: 0;
}

.contactButton > p:nth-child(3) {
  color: #317cfb;
}

.contactButton > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em 0 0 0;
}
.contactButton > div > a {
  font-size: calc(20px + (40 - 20) * ((100vw - 350px) / (2500 - 350)));
  background: #fbb03c;
  color: #fff;
  padding: 0.8em 3em;
  margin: 0 1em 0 1em;
  border-radius: 3em;
  text-decoration: none;
}

.wave {
  width: 100%;
  display: block;
}

.buttonLinesLeft {
  height: calc(50px + (100 - 50) * ((100vw - 350px) / (2500 - 350)));
}
.buttonLinesRight {
  height: calc(50px + (100 - 50) * ((100vw - 350px) / (2500 - 350)));
  transform: scale(-1, 1);
}

.contactFooter {
  padding: 0 0 4em 0;
  background: #317cfb;
  width: 100%;
  display: block;
  flex-direction: column;
  margin: 0;
}

.logo {
  height: 4em;
  display: block;
  margin: 2em 0 4em 8vw;
}

.contactFooter > div {
  padding: 0 10vw;
}

.contactFooter > div > h3 {
  font-size: calc(20px + (35 - 20) * ((100vw - 350px) / (2500 - 350)));
}
.contactFooter > div > p {
  font-size: calc(18px + (25 - 18) * ((100vw - 350px) / (2500 - 350)));
  font-weight: 300;
}
