.partners {
  width: 100%;
  font-size: 1em;
  padding: 4em 0;
}

.wrapper {
  background: #eeeeee;
  width: 100%;
  font-size: 1em;
  padding: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper > p {
  color: #666;
  font-size: calc(25px + (30 - 25) * ((100vw - 350px) / (2500 - 350)));
  font-weight: 300;
  line-height: 1.8em;
  max-width: 650px;
  margin: 0;
  padding: 0 1em;
}

.partnerslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.partner {
  height: 130px;
  width: 300px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 900px) {
  .wrapper {
    flex-direction: column;
    padding: 3em 0;
  }
  .partnerslist {
    padding-top: 2em;
  }
}

@media (max-width: 680px) {
  .wrapper > p {
    text-align: center;
  }
}
