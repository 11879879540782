.createPostContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
}

.banner {
  position: relative;
}

.banner > img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.bannerWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4em 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.bannerWrapper > h1 {
  text-align: center;
  color: #fff;
  font-size: 4em;
  font-weight: 500;
}

.content {
  width: 70%;
}

.post {
  text-decoration: none;
  color: #666;
  width: 100%;
  max-width: 70%;
  padding: 2em 0;
  margin-bottom: 1em;
  display: block;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.likes {
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.likes > svg {
  font-size: 2em;
  color: #d01c2d;
}
.likes > span {
  font-size: 1.5em;
  padding: 0.5em 0 0 0;
  display: block;
}

.post > div:nth-child(2) {
  padding: 0 0 0 1em;
}

.title {
  font-size: 2.4em;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0 0 1em 0;
}

.bottomSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottomSection > div {
  padding: 0 1.4em 0 0;
}

.bottomSection > div > span {
  padding: 0 0 0 0.5em;
}

.bottomSection > div > svg {
  color: rgba(255, 255, 255, 0.6);
}
