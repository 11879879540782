.team {
  width: 100%;
  font-size: 1em;
  background: #fff;
  padding: 4em 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
}

.team > p {
  font-size: calc(28px + (70 - 28) * ((100vw - 350px) / (2500 - 350)));
  text-align: center;
  padding: 1em 1em 0.5em 1em;
  color: #555;
  font-weight: 600;
  max-width: 900px;
  line-height: 1.4em;
}
.team > p > strong {
  height: 1.4em;
  display: block;
}

.team > p > strong > span {
  border-right-width: 4px;
  border-right-color: currentColor;
  border-right-style: solid;
  padding-right: 0.1em;
  animation-name: NAME-YOUR-ANIMATION; /* IE 10+, Fx 29+ */
  animation-duration: 0.1s;
  animation-timing-function: linear;
}

@keyframes NAME-YOUR-ANIMATION {
  0% {
    border-right-color: currentColor;
  }
  100% {
    border-right-color: transparent;
  }
}

.team > a {
  font-size: 2em;
  font-size: calc(25px + (50 - 25) * ((100vw - 350px) / (2500 - 350)));
  background: #fbb03c;
  color: #fff;
  padding: 0.8em 3em;
  border-radius: 3em;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em 0 0 0;
}
.buttonContainer > a {
  font-size: 2em;
  font-size: calc(20px + (40 - 20) * ((100vw - 350px) / (2500 - 350)));
  background: #fbb03c;
  color: #fff;
  padding: 0.8em 3em;
  margin: 0 1em 0 1em;
  border-radius: 3em;
}

.buttonLinesLeft {
  height: 5em;
}
.buttonLinesRight {
  height: 5em;
  transform: scale(-1, 1);
}
