.createPostContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
}

.banner {
  position: relative;
}

.banner > img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.bannerWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4em 0 0 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.bannerWrapper > h1 {
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 4em;
}

.postList {
  width: 80%;
  padding: 1em;
}

.post {
  text-decoration: none;
  color: #666;
  background: #fff;
  width: 100%;
  padding: 1em;
  margin-bottom: 1em;
  display: block;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: transform 0.2s ease;
}
.post:hover {
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.08);
  transform: translate(-3px, -3px);
}

.likes {
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.likes > svg {
  font-size: 1.4em;
  color: #d01c2d;
}
.likes > span {
  padding: 0.5em 0 0 0;
  display: block;
}

.post > div:nth-child(2) {
  padding: 0 0 0 1em;
}

.title {
  font-size: 1.2em;
  font-weight: 500;
  color: #3994d1;
}
.description {
  font-size: 1em;
  font-weight: 400;
  padding: 0.5em 0 1em 0;
}

.bottomSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottomSection > div {
  padding: 0 1.4em 0 0;
}

.bottomSection > div > span {
  padding: 0 0 0 0.5em;
}

.bottomSection > div > svg {
  color: #3994d1;
}
