.intro {
  width: 100%;
  font-size: 1em;
  color: #fff;
  position: relative;
}

.introText {
  padding: 8em 10vw 0 10vw;
  background: #317cfb;
  position: relative;
}

.introText > h2 {
  font-weight: bold;
  margin: 0;
  z-index: 2;
  font-size: calc(32px + (80 - 32) * ((100vw - 350px) / (2500 - 350)));
  height: 1.3em;
  width: auto;
  z-index: 2;
  position: relative;
}

.introText > h2 > span {
  border-right-width: 4px;
  border-right-color: currentColor;
  border-right-style: solid;
  padding-right: 0.1em;
  animation-name: NAME-YOUR-ANIMATION; /* IE 10+, Fx 29+ */
  animation-duration: 0.1s;
  animation-timing-function: linear;
}

@keyframes NAME-YOUR-ANIMATION {
  0% {
    border-right-color: currentColor;
  }
  100% {
    border-right-color: transparent;
  }
}

.introText > p {
  font-weight: 300;
  max-width: 20em;
  margin: 0;
  padding: 0;
  z-index: 2;
  font-size: calc(26px + (30 - 26) * ((100vw - 350px) / (2500 - 350)));
  position: relative;
}

.wave {
  width: 100%;
}

.pixel {
  z-index: 1;
}

@media (max-width: 900px) {
  .intro {
    min-height: auto;
  }

  .pixel {
    display: none;
  }
}
