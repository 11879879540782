.about {
  width: 100%;
  font-size: 1em;
  color: #fff;
  background: #fff;
  padding: 4em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  width: 100%;
  max-width: 350px;
  padding: 1em;
}
.logo {
  display: block;
  width: 100%;
}
.textContainer {
  padding: 2em 0 0 5em;
  position: relative;
}

.textContainer > h2 {
  color: #666;
  font-size: calc(30px + (70 - 30) * ((100vw - 350px) / (2500 - 350)));
  font-weight: bold;
  margin: 0;
  padding: 0 0 0.6em 0;
  z-index: 2;
}

.textContainer > p {
  color: #666;
  font-size: calc(20px + (26 - 20) * ((100vw - 350px) / (2500 - 350)));
  font-weight: 300;
  line-height: 1.8em;
  max-width: 650px;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.textContainer > p > strong {
  color: #fbb03c;
}

.stats {
  color: #666;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0 0 0;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1em 2em 0 0;
}
.stat > span {
  color: #555;
  font-weight: bold;
  font-size: 1.2em;
}

.stat > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
}

.stat > div > span:nth-child(1) {
  font-size: 3em;
  font-weight: bold;
  color: #317cfb;
}

.stat > div > span:nth-child(2) {
  font-size: 2em;
  font-weight: bold;
  color: #fbb03c;
}

@media (max-width: 900px) {
  .about {
    flex-direction: column;
  }
  .textContainer {
    padding: 2em 0;
  }
}
